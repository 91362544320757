var mainMenu = $("#mobile-navigation");

$('.hamburger').on('click', function(){
    if($(mainMenu).hasClass('is-active')){
        closeMenu();
    } else {
        openMenu();
    }
});

function openMenu() {
    var menuHeight = "100vh";

    // Trap our focus in menu when its open
    var tabbable = $('.main-header', mainMenu).find('select, input, textarea, button, a').filter(':visible');
    var firstTabbable = $(tabbable).first();
    var lastTabbable = $(tabbable).last();

    $(firstTabbable).focus();

    /*redirect last tab to first input*/
    lastTabbable.on('keydown', function (e) {
        if ((e.which === 9 && !e.shiftKey)) {
            e.preventDefault();
            firstTabbable.focus();
        }
    });

    /*redirect first shift+tab to last input*/
    firstTabbable.on('keydown', function (e) {
        if ((e.which === 9 && e.shiftKey)) {
            e.preventDefault();
            lastTabbable.focus();
        }
    });

    /*redirect last tab to first input*/
    lastTabbable.on('keydown', function (e) {
        if ((e.which === 9 && !e.shiftKey)) {
            e.preventDefault();
            firstTabbable.focus();
        }
    });

    var tl = anime.timeline({
        easing: "easeOutQuart",
        duration: 450,
        begin: () => {
            $(".hamburger").addClass("is-active");
            $(mainMenu).addClass("is-active");
            $("body").addClass("no-scroll");
            $(".sub-nav-wrapper").addClass('is-active');
            $('header.main-header').addClass('is-active');
        },
    });
    tl.add({
        targets: mainMenu[0],
        height: [0, menuHeight]
    }).add(
        {
            targets: "#mobile-navigation li" ,
            translateX: ["5%", 0],
            opacity: [0, 1],
            delay: anime.stagger(35)
        },
        "-=200"
    ).add({
        targets: '.mobile-nav-bg-img',
        translateY: [0, "2.5%"],
        scale: [1, 1.05],
        opacity: [0.5, 1],
    }, "-=600");

    anime({
        targets: '.mobile-page-title',
        opacity: 0
    })
}

// Close menu function
function closeMenu() {
    var menuHeight = "100vh";

    var tl = anime.timeline({
        easing: "easeOutQuart",
        duration: 250,
        begin: () => {
            $(".hamburger").removeClass("is-active");
            $(mainMenu).removeClass("is-active");
            $("body").removeClass("no-scroll");
            $(".sub-nav-wrapper").removeClass('is-active');
            $('header.main-header').removeClass('is-active');
        },
    });
    tl.add({
        targets: "#mobile-navigation li" ,
        translateX: [0, "-5%"],
        opacity: [1, 0],
        delay: anime.stagger(35, {
            from: "last"
        })
    }).add(
        {
            targets: mainMenu[0],
            height: [menuHeight, 0]
        },
        "-=400"
    );

    anime({
        targets: '.mobile-page-title',
        opacity: 1
    })
}