//-----------------------------------
// scripts.js
// Author: @codymarcoux (Studio123)
//
// NOTE: Main scripts file
//-----------------------------------
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
let vhMenu = window.innerHeight * 0.01;

// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);
document.documentElement.style.setProperty('--vhMenu', `${vhMenu}px`);

// We listen to the resize event
// Only want to resize the menu to prevent janky impact area
window.addEventListener('resize', () => {
    // We execute the same script as before
    let vhMenu = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vhMenu', `${vhMenu}px`);
    if ($(window).width() >= 769) {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
});

var myElement = document.querySelector("header.main-header");
var options = {
    offset : 125,
    tolerance : {
        up : 10,
        down : 0
    },
}
var headroom  = new Headroom(myElement, options);
headroom.init();