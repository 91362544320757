function wrapWordsInSpan(str) {
    return str.replace(/([A-zàâçéèêëîïôûùüÿñæœ.-0-9'<>/]+)/g, "<span class='word'>$&</span>");
}

function throttle (callback, limit) {
  var wait = false;                  
  return function () {              
      if (!wait) {                  
          callback.call();         
          wait = true;               
          setTimeout(function () {  
              wait = false;         
          }, limit);
      }
  }
}

function isScrollable(div){
    return div.get(0).scrollWidth > div.get(0).clientWidth;
}

function addZero(n) {
    return (n < 10 ? '0' : '') + n;
}