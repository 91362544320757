const lines = $('.callout-line-svg');
const linesPositions = [];

if(lines.length > 0){
    initLineAnimations(lines);
    window.addEventListener('scroll', throttle(animateLines, 500));
}

function initLineAnimations(lines){
    lines.each((i, line) => {
        const lineBottomPos = $(line).offset().top + $(line).innerHeight();
        linesPositions.push(lineBottomPos);
    })

    $(lines).find('svg path').each((i, line) => {
        const lineLength = line.getTotalLength();
        line.style.strokeDasharray = lineLength;
        line.style.strokeDashoffset = lineLength;
    });
}

function animateLines(){
    const scrollPosition = $(window).scrollTop() + $(window).innerHeight();

    $(linesPositions).each(function(i, linePosition){
        if(scrollPosition > linePosition){

            if($($('.callout-line-svg')[i]).hasClass('is-animated')){ 
                return true;
            }

            anime({
                targets: $('.callout-line-svg svg#desktop path')[i],
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutQuint',
                duration: 2000,
                direction: 'forwards',
            });

            anime({
                targets: $('.callout-line-svg svg#mobile path')[i],
                strokeDashoffset: [anime.setDashoffset, 0],
                easing: 'easeInOutQuint',
                duration: 2000,
                direction: 'forwards',
            });

            $($('.callout-line-svg')[i]).addClass('is-animated');
        }
    });
}