const subNav = $('nav.sub-page-navigation');
const subNavLinks = $('nav.sub-page-navigation a');
const subNavHighlight = $('.sub-link-highlighter');
const subNavHeight = $(subNav).innerHeight(); 
const isScrollingToTarget = false;
const scrollItems = subNavLinks.map(function() {
    var section = $($(this).attr("href"));
    if ($(section).length > 0) {
      return section;
    }
});
let lastSection;

$(window).on('scroll', throttle(checkHighlightedSection, 250));

function checkHighlightedSection(){
    const scrollPos = $(document).scrollTop() + subNavHeight;
    
    if(isScrollingToTarget) return false;

    $(scrollItems).each((index, section) => {
        const sectionTop = $(section).offset().top;
        const sectionBottom = $(section).offset().top + $(section).innerHeight();

        if((scrollPos * 1.05) > sectionTop && scrollPos < sectionBottom){
            const sectionID = $(section).attr('id');
            const navLink = $(`a[href="#${sectionID}"]`);

            if(lastSection != sectionID){
                moveHighlight(navLink, index);
            }
            lastSection = sectionID;
        }
    })
}

initLinkHighlight(subNavLinks);

function initLinkHighlight(links){
    if(!links || $(subNav).length <= 0) return false;
    const canScroll = isScrollable(subNav);
    const firstLinkPositionX = $(links).first().offset().left;
    $(subNavHighlight).addClass('is-active');

    if(canScroll){
        $(subNavHighlight).addClass('is-scrollable');
        $(subNavHighlight).css('transform', `translateX(24px)`);
    } else {
        $(subNavHighlight).css('transform', `translateX(${firstLinkPositionX}px)`);
        $(subNavHighlight).removeClass('is-scrollable');
    }
}

function moveHighlight(link, index){
    const canScroll = isScrollable($(subNav));
    const linkPositionX = $(link).offset().left;

    if(canScroll){
        $(subNavHighlight).addClass('is-scrollable');
        $(subNav).scrollTo(link, 250, { offset: -24 });
    } else {
        $(subNavHighlight).removeClass('is-scrollable');
        $(subNavHighlight).css('transform', `translateX(${linkPositionX}px)`);
    }
}

adjustSubNavSpacerWidth();

function adjustSubNavSpacerWidth(){
    const spacer = $('.nav-spacer');
    const browserWidth = $(window).outerWidth();
    const lastLinkWidth = $('nav.sub-page-navigation a').last().width();
    const paddingOffset = 48;

    const spacerWidth = browserWidth - lastLinkWidth - paddingOffset;
    $(spacer).width(spacerWidth);
}

var resizeTimer;

$(window).on('resize', function(e) {

  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function() {

    adjustSubNavSpacerWidth();
    initLinkHighlight(subNavLinks);
            
  }, 250);

});