
if($('.section--impact_homepage').length > 0){
    initImpactAnimation();
}

function initImpactAnimation(){
    const impactText = $('.section--impact_homepage').find('h1')[0];

    impactText.innerHTML = wrapWordsInSpan(impactText.innerText);

    anime({
        targets: '.section--impact_homepage .word',
        opacity: [0,1],
        translateX: ["5%",0],
        translateY: ["10%",0],
        easing: "easeOutQuint",
        duration: 1000,
        delay: (el, i) => 50 * i
    });

    anime({
        targets: '.impact-line-svg svg path',
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInOutQuint',
        duration: 2500,
        direction: 'forwards',
    });

}