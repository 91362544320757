$('button.view-description').on('click', function(){
    toggleDescription(this);
})

function toggleDescription(button){
    const content = $(button).siblings('.description');

	if (!content) return;
    
	if ($(button).attr('aria-expanded') === 'true') {
        $(button).attr('aria-expanded', false);
        $(button).removeClass('is-active');
        $(content).attr('hidden', true);
	} else {
		$(button).attr('aria-expanded', true);
        $(button).addClass('is-active');
        $(content).attr('hidden', false);
	}
}